<template>
  <div class="document" v-if="loaded">
    <div class="document-header">
      <div class="document-header-type">
        <select-dropdown :items="types" v-model="item.type_id" :isDisabled="loading"></select-dropdown>
      </div>

      <p>№</p>

      <div class="document-header-number">
        <b-input placeholder="100" :disabled="loading" v-model="item.number"></b-input>
      </div>

      <p>от</p>

      <div class="document-header-date">
        <date-picker :disabled="loading" :clear-button="false" :value="item.date" @input="setItemDate"></date-picker>
      </div>
      <p>статус {{ documentNameRodLower }}:</p>

      <div class="document-header-status">
        <select-dropdown
          :items="statuses[+item.type_id] || []"
          :isDisabled="loading"
          v-model="item.status_id"
        ></select-dropdown>
      </div>

      <div class="document-header-submit">
        <b-button @click.prevent.stop="saveItem" variant="primary" :disabled="loading || !changed">
          <template v-if="item.id">Сохранить {{ documentNameLower }}</template>
          <template v-else>Создать {{ documentNameLower }}</template>
        </b-button>
      </div>
    </div>

    <div class="document-client">
      <h4>Клиент:</h4>
      <select-dropdown
        :ajax-async="searchClient"
        :placeholder="
          item.person
            ? `${item.person.fields.name} (${item.person.fields.full_name})`
            : 'Начните вводить название клиента'
        "
        v-model="item.person_to_id"
        :isDisabled="loading"
      ></select-dropdown>
    </div>

    <div class="document-positions">
      <h4>Позиции {{ documentNameRodLower }}:</h4>
      <div class="document-positions-table">
        <table>
          <thead>
            <tr>
              <th class="_number">№</th>
              <th class="_name">Наименование</th>
              <th class="_unit">Ед.</th>
              <th class="_quantity">Кол-во</th>
              <th class="_price">Стоимость</th>
              <th class="_total">Сумма</th>
              <th class="_delete">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(position, key) in item.positions">
              <td class="_number">
                <p>{{ position.number }}</p>
              </td>
              <td class="_name">
                <b-input :disabled="loading" placeholder="Оплата за внедрение amoCRM" v-model="position.name"></b-input>
              </td>
              <td class="_unit">
                <b-input :disabled="loading" placeholder="ед." v-model="position.unit"></b-input>
              </td>
              <td class="_quantity">
                <b-input :disabled="loading" placeholder="1" v-model="position.quantity"></b-input>
              </td>
              <td class="_price">
                <b-input :disabled="loading" placeholder="20000" v-model="position.price"></b-input>
              </td>
              <td class="_total">
                <p>{{ getPositionTotal(position) | formatNumber }}</p>
              </td>
              <td class="_delete" @click.prevent.stop="deletePosition(key)">
                <i class="zmdi zmdi-delete"></i>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="_totalName">ИТОГО</td>
              <td class="_totalCount">
                <p>{{ totalQuantity | formatNumber }}</p>
              </td>
              <td class="_totalPrice">
                <p>{{ totalPrice | formatNumber }}</p>
              </td>
              <td class="_totalTotal">
                <p>{{ totalTotal | formatNumber }}</p>
              </td>
            </tr>
            <tr v-if="!loading">
              <td colspan="7" class="_add" @click.prevent.stop="addPosition">
                + добавить позицию {{ documentNameRodLower }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="document-positions-table-preview">
          <div class="document-positions-table-preview-box">preview</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { cloneDeep, sum } from 'lodash'
  import notification from '../../../assets/js/notification'
  import api from '../../../assets/js/api'

  import moment from 'moment'
  import DatePicker from '../../../components/DatePicker'
  import SelectDropdown from '../../../components/SelectDropdown'

  export default {
    name: 'ViewDocument',
    components: {
      SelectDropdown,
      DatePicker,
    },
    computed: {
      documentNameLower() {
        return this.documentName && this.documentName.toLowerCase()
      },
      documentName() {
        return {
          2: 'Счет',
          3: 'Акт',
        }[this.item.type_id]
      },
      documentNameRod() {
        return {
          2: 'Счета',
          3: 'Акта',
        }[this.item.type_id]
      },
      documentNameRodLower() {
        return this.documentNameRod && this.documentNameRod.toLowerCase()
      },

      changed() {
        return JSON.stringify(this.originalItem) !== JSON.stringify(this.item)
      },
      totalQuantity() {
        return (
          (this.item &&
            this.item.positions &&
            sum(
              this.item.positions.map(position => {
                return parseFloat(position.quantity)
              })
            )) ||
          0
        )
      },
      totalPrice() {
        return (
          (this.item &&
            this.item.positions &&
            sum(
              this.item.positions.map(position => {
                return +position.price
              })
            )) ||
          0
        )
      },
      totalTotal() {
        return (
          (this.item &&
            this.item.positions &&
            sum(
              this.item.positions.map(position => {
                return +(position.price * parseFloat(position.quantity))
              })
            )) ||
          0
        )
      },
    },
    data() {
      return {
        loaded: false,
        loading: false,

        statuses: {
          2: [
            { name: 'Оплачен', value: 10 },
            { name: 'Частично оплачен', value: 11 },
            { name: 'Не оплачен', value: 12 },
          ],
          3: [
            { name: 'Подписан', value: 13 },
            { name: 'Не подписан', value: 14 },
          ],
        },
        types: [
          { name: 'Счет', value: 2 },
          { name: 'Акт', value: 3 },
        ],
        originalItem: {},
        item: {},
      }
    },
    async mounted() {
      if (this.$route.params.id === 'new') {
        this.originalItem = {
          number: '',
          type_id: '',
          status_id: '',
          date: moment().format('YYYY-MM-DD'),
          positions: [],
        }
      } else {
        const { data } = await api.http.get(`accounting/documents/${this.$route.params.id}`, {
          params: {
            with: {
              documents: 'items,personTo,personTo.fields',
            },
          },
        })

        this.originalItem = {
          id: data.id,
          hash: data.hash,
          number: data.number,
          person: data.personTo,
          client_id: data.person_to_id,
          type_id: data.type_id,
          status_id: data.status_id,
          date: moment(data.created_at).format('YYYY-MM-DD'),
          positions: data.items
            .filter(item => {
              try {
                const extra = JSON.parse(item._extra)
                return true
              } catch (e) {
                return false
              }
            })
            .map(item => {
              try {
                const extra = JSON.parse(item._extra)

                return {
                  id: item.id,
                  name: extra.comment,
                  unit: extra.unit,
                  quantity: parseFloat(extra.quantity),
                  price: parseFloat(extra.price),
                  number: item.number,
                }
              } catch (e) {
                return false
              }
            }),
        }
      }

      this.item = cloneDeep(this.originalItem)

      this.loaded = true
    },
    methods: {
      async searchClient(query) {
        const { items } = await api.base.get({
          requestId: 'searchClient',
          path: 'clients',
          params: {
            filters: {
              clients: {
                name: query,
              },
            },
            with: {
              clients: 'person,person.fields',
            },
          },
        })

        return items
          .filter(item => {
            return item.person && item.person.id
          })
          .map(item => {
            return {
              name: `${item.person.fields.name} (${item.person.fields.full_name})`,
              value: item.person.id,
            }
          })
      },

      addPosition() {
        this.item.positions.push({
          id: null,
          number: this.item.positions.length + 1,
          name: '',
          unit: '',
          quantity: 1,
          price: 0,
          total: 0,
        })
      },
      deletePosition(key) {
        this.item.positions.splice(key, 1)

        this.item.positions.forEach((val, key) => {
          this.$set(this.item.positions[key], 'number', key + 1)
        })
      },
      getPositionTotal(position) {
        return position.quantity * position.price
      },
      setItemDate(e) {
        this.$set(this.item, 'date', moment(e).format('YYYY-MM-DD'))
        console.log(e)
      },
      async saveItem() {
        console.log(this.item)

        // const data = await api.base.update({
        //   entity: 'documents',
        //   path: 'accounting/documents',
        //   id: this.item.id ? this.item.id : null,
        //   data: this.item,
        //   to: this.$route,
        //   from: this.$route
        // })

        try {
          const data = await api.httpClean({
            url: `accounting/documents${this.item.id ? '/' + this.item.id : ''}`,
            method: !this.item.id ? 'post' : 'put',
            data: {
              id: this.item.id,
              type_id: this.item.type_id,
              status_id: this.item.status_id,
              number: this.item.number,
              person_from_id: 999,
              person_to_id: this.item.person_to_id,
              date: moment(this.item.date).format('YYYY-MM-DD'),
              items: this.item.positions,
            },
            withCredentials: true,
          })

          notification.success(`${this.documentName} успешно ${!this.item.id ? 'создан' : 'сохранен'}`)
        } catch (e) {
          notification.error(`Ошибка при ${!this.item.id ? 'создании' : 'сохранении'} ${this.documentNameRodLower}`)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .document {
    width: 100%;
    background: #fff;
    padding: 2rem;

    &-header {
      display: flex;
      align-items: center;

      > p {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        margin: 0;
      }

      &-type {
        width: 100px;
      }

      &-number {
        width: 100px;
      }

      &-date {
        width: 120px;
      }

      &-status {
        width: 150px;
      }

      &-submit {
        margin-left: auto;
      }
    }

    &-client {
      padding: 1rem 0 0 0;
      width: calc(70% - 1rem);
    }

    &-positions {
      padding: 1rem 0 0 0;

      &-table {
        display: flex;

        table {
          table-layout: fixed;
          width: calc(70% - 1rem);
          margin-right: 1rem;

          thead {
            tr {
              th {
                height: 30px;
                vertical-align: middle;
                border-bottom: 1px solid #eee;

                &._number {
                  width: 50px;
                  text-align: center;
                }

                &._name {
                  min-width: 150px;
                }

                &._unit {
                  width: 60px;
                  text-align: center;
                }

                &._quantity {
                  width: 70px;
                  text-align: center;
                }

                &._price {
                  width: 120px;
                  text-align: center;
                }

                &._total {
                  width: 120px;
                  text-align: center;
                }

                &._delete {
                  width: 30px;
                  text-align: center;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                height: 40px;
                vertical-align: middle;

                input[type='text'] {
                  border: 0;
                  background: #fafafa;
                  width: calc(100% - 4px);
                  height: calc(100% - 4px);
                  margin: 2px;
                  padding: 0 5px;
                  text-align: center;
                }

                &._name {
                  input[type='text'] {
                    font-weight: normal;
                    text-align: left;
                  }
                }

                &._totalName {
                  text-align: right;
                  font-weight: bold;
                  padding-right: 2px;
                }

                &._totalCount,
                &._totalPrice,
                &._totalTotal {
                  font-weight: bold;
                }

                &._delete {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &:hover {
                    .zmdi-delete {
                      color: #f30;
                    }
                  }

                  .zmdi-delete {
                    font-size: 14px;
                  }
                }

                > p {
                  cursor: default;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  border: 0;
                  background: #fafafa;
                  width: calc(100% - 4px);
                  height: calc(100% - 4px);
                  margin: 2px;
                  font-weight: bold;
                }

                &._add {
                  text-align: center;
                  font-weight: 400;
                  cursor: pointer;

                  &:hover {
                    color: #000;
                  }
                }
              }
            }
          }
        }

        &-preview {
          width: 30%;
          padding: 0 0 0 1rem;

          &-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            min-width: 250px;
            min-height: 400px;
            border: 1px solid #eee;
            border-radius: 3px;
            background: #fafafa;
          }
        }
      }
    }
  }
</style>
